import React, { createContext, useState } from "react";

export const OrderContext = createContext();

function OrderState(props) {
  const [order, setOrder] = useState([]);

  const [isFinished, setFinished] = useState(false);

  const updateOrder = (quantity, drinkName, priceUI, id) => {
    console.log("UPDATE ORDER IN CONTEXT CALLED: ", quantity);
    var newOrder = order;
    var index = newOrder.findIndex((eachOrder) => {
      return eachOrder.name === drinkName;
    });
    if (index === -1) {
      newOrder.push({
        id: id,
        name: drinkName,
        amount: quantity,
        price: priceUI,
        totalPrice: quantity * priceUI,
      });
      setOrder(newOrder);
    } else {
      var updatedOrder = order.map((eachDrink) => {
        if (eachDrink.name === drinkName) {
          return {
            id: id,
            name: drinkName,
            amount: quantity,
            price: priceUI,
            totalPrice: quantity * priceUI,
          };
        } else {
          return { ...eachDrink };
        }
      });
      setOrder((prevState) => updatedOrder);
    }
  };
  //Clear order
  const clearOrder = () => {
    setOrder([]);
    // setTotalPrice(0);
  };

  //Finish order process
  const finishOrder = () => {
    setFinished(true);
  };

  return (
    <OrderContext.Provider
      value={{
        order,
        updateOrder,
        clearOrder,
        finishOrder,
        isFinished,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
}

export default OrderState;
