import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
/**
 * Component that checks if the user is logged in before rendering the route-component
 * @module PrivateRoute Component
 */
function PrivateRoute({ component: Component, ...rest }) {
  const userContext = useContext(UserContext);
  const { isLoggedIn } = userContext;
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn ? <Redirect to='/' /> : <Component {...props} />
      }
    />
  );
}

export default PrivateRoute;
