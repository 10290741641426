import React, { Fragment } from "react";
import { CircularProgress } from "@material-ui/core";
/**
 * Spinner layout component displayed when the app is fetching data
 * @module Spinner Component
 */
function Spinner() {
  return (
    <Fragment>
      <CircularProgress style={{ color: "#666" }}></CircularProgress>
    </Fragment>
  );
}

export default Spinner;
