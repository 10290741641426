import React, { useContext } from "react";
import { getToday } from "../services/index";
import { AppBar, Button, Toolbar, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import logo from "../assets/mxm_logo.png";
import { UserContext } from "../context/UserContext";
import { OrderContext } from "../context/OrderContext";
/**
 * The Header of the application built with Appbar material-ui component
 * This component uses material-ui hook "makeStyles" for styling
 * @module Navbar Component
 */
const useStyes = makeStyles(() => ({
  logo: {
    flex: 1,
    maxWidth: "35px",
  },
  username: {
    marginRight: "2rem",
  },
  todayDate: {},
  toolbar: {
    justifyContent: "space-between",
  },
  headerStyle: {
    marginBottom: "20px !important",
    backgroundColor: "#fff",
    boxShadow: "0 0 0 0",
    color: "#666",
    borderBottomLeftRadius: "25px",
    borderBottomRightRadius: "25px",
    minHeight: "70px",
    padding: "0 1.5rem",
  },
  boxElement: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btn: {
    borderRadius: "25px",
    textTransform: "capitalize",
    color: "#666",
  },
}));

function Navbar() {
  const classes = useStyes();
  const { isLoggedIn, displayName, goBack } = useContext(UserContext);
  const { clearOrder, isFinished } = useContext(OrderContext);
  const today = getToday();

  const handleGoBack = () => {
    clearOrder();
    goBack();
  };
  return (
    <AppBar position='static' className={classes.headerStyle}>
      <Toolbar className={classes.toolbar}>
        <a href='/'>
          <img src={logo} className={classes.logo} alt='maxomedia'></img>
        </a>
        {isLoggedIn && !isFinished ? (
          <Box className={classes.boxElement}>
            <Typography className={classes.username}>{displayName}</Typography>
            <Button
              onClick={handleGoBack}
              variant='outlined'
              className={classes.btn}
            >
              Zurück
            </Button>
          </Box>
        ) : (
          <Typography className={classes.todayDate}>{today}</Typography>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
