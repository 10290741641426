import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import LoadingOverlay from "react-loading-overlay";
import { Grid } from "@material-ui/core";
import DrinkCard from "./DrinkCard/DrinkCard";
import Spinner from "../../layout/Spinner";
import BottomDock from "../../layout/BottomDock/BottomDock";
import { getRequest } from "../../utils/http/http";
import beer from "../../assets/beer.svg";
import softDrink from "../../assets/soft.svg";
import premiumDrink from "../../assets/premium.svg";
import { Environment } from "../../global/environment";
import wine from "../../assets/wine.svg";
import coffeCup from "../../assets/test.svg";
import DarkBackground from "./DarkBackground";
import "./styles.css";
/**
 * Component that renders each drink using DrinkCard
 * This component uses material-ui makeStyles hook for styling
 * @module Drinks Component
 */

const images = [coffeCup, softDrink, premiumDrink, beer, wine];

function Drinks() {
  const [fetching, setFetching] = useState(true);
  const [order, setOrder] = useState([]);
  const iconArr = useRef([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [finish, setFinish] = useState(false);

  const findOrder = (name, id, remove = "") => {
    if (remove === "minus") {
      if (order[id - 1].amount === 0) {
        iconArr.current = iconArr.current.filter((d) => d.id !== id);
        return;
      }
    }
    if (iconArr.current.filter((d) => d.name === name).length > 0) {
      // iconArr.current[id - 1].amount += 1;
      return;
    }
    iconArr.current.push(order[id - 1]);
  };
  useEffect(() => {
    const getDrinks = async () => {
      try {
        let drinks = await getRequest(`${Environment.BACKEND_API}/DrinkGroup`);

        let initialOrder = [];
        initialOrder = drinks.map((drink, index) => {
          return {
            ...drink,
            amount: 0,
            imgUrl: images[index],
          };
        });

        setOrder(initialOrder);
        setFetching(false);
      } catch (err) {
        console.log(err);
        setFetching(false);
      }
    };
    getDrinks();
  }, []);
  // eslint-disable-next-line
  useEffect(() => calculatetotalPrice, [order]);

  //THIS FUNCTION IS USED BY ALL THE CARDS RENDERED
  //IN THE DRINKS MENU TO INCREASE THE NUMBER OF DRINKS
  const increment = (id, name) => {
    let updateOrder = order;
    for (let i = 0; i < updateOrder.length; i++) {
      if (updateOrder[i].id === id) {
        updateOrder[i].amount += 1;
        break;
      }
    }
    setOrder([...updateOrder]);
    findOrder(name, id);
  };

  //THIS FUNCTION IS USED BY ALL THE
  //CARDS RENDERED IN THE DRINKS MENU TO DECREASE THE NUMBER OF DRINKS
  const decrement = (id, name) => {
    let updateOrder = order;
    for (let i = 0; i < updateOrder.length; i++) {
      if (updateOrder[i].id === id && updateOrder[i].amount > 0) {
        updateOrder[i].amount -= 1;
        break;
      }
    }
    setOrder([...updateOrder]);
    findOrder(name, id, "minus");
  };
  const calculatetotalPrice = () => {
    let total = 0;
    for (let i = 0; i < order.length; i++) {
      total += order[i].amount * order[i].price;
    }
    setTotalPrice(total);
  };
  const clearOrder = () => {
    let emptyOrder = order;
    for (let i = 0; i < order.length; i++) {
      emptyOrder[i].amount = 0;
    }
    iconArr.current = [];
    setTimeout(() => {
      setOrder([...emptyOrder]);
    }, 100);
  };
  const finishHandler = () => {
    setFinish(true);
  };

  return (
    <>
      {fetching ? (
        <Spinner />
      ) : (
        <motion.div
          initial={{ x: "100vw" }}
          animate={{ x: 0 }}
          transition={{ type: "spring", delay: 0.5, duration: 1.3 }}
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "1rem",
            paddingTop: "1rem",
          }}
        >
          {order.map((drink) => (
            <Grid item key={drink.id}>
              <DrinkCard
                name={drink.name}
                price={drink.price}
                id={drink.id}
                amount={drink.amount}
                increment={increment}
                decrement={decrement}
                imgSrc={images[drink.id - 1]}
              ></DrinkCard>
            </Grid>
          ))}
        </motion.div>
      )}
      <BottomDock
        order={order}
        totalPrice={totalPrice}
        clearOrder={clearOrder}
        finishHandler={finishHandler}
        iconArray={iconArr}
      />
      <DarkBackground disappear={finish}>
        <LoadingOverlay
          active={finish}
          spinner={true}
          text='Processing order...'
        ></LoadingOverlay>
      </DarkBackground>
    </>
  );
}

export default Drinks;
