import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import Users from "../../components/Users/Users";
import { motion } from "framer-motion";
import { UsersContext } from "../../context/UsersContext";
import Spinner from "../../layout/Spinner";
import FilterUsers from "../../components/Users/FilterUsers/SearchBar";
import { makeStyles } from "@material-ui/styles";
/**
 * Home page/component used as a layout to render all Maxomedia Employees
 * This component uses material-ui hook "makeStyles" for styling
 * @module UsersPage Component
 */
const useStyles = makeStyles(() => ({
  root: {},
  users: {},
  filterUsers: {
    marginBottom: "0.5rem",
  },
}));

function UsersPage() {
  const classes = useStyles();
  const { isLoading } = useContext(UsersContext);

  return (
    <Grid
      container
      xs={12}
      item
      justify='center'
      className={classes.root}
      component={motion.div}
      exit={{ x: "-100vw" }}
      transition={{ duration: 1 }}
    >
      {!isLoading ? (
        <>
          <Grid item xs={12} container justify='center'>
            <Grid item className={classes.filterUsers}>
              <FilterUsers />
            </Grid>
          </Grid>
          <Users />
        </>
      ) : (
        <Grid item>
          <Spinner />
        </Grid>
      )}
    </Grid>
  );
}

export default UsersPage;
