import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100px",
    position: "fixed",
    zIndex: "999",
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#276e64",
    borderTopRightRadius: "25px",
    borderTopLeftRadius: "25px",
    // eslint-disable-next-line
    ["@media (max-width:766px)"]: {
      justifyContent: "flex-end",
    },
  },

  leftDiv: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginLeft: "30px",
    // eslint-disable-next-line
    ["@media (max-width:766px)"]: {
      display: "none",
    },
  },
  rightDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "30px",
    color: "#fff",
  },
  rightDivLeft: {
    width: "90px",
  },
  btn: {
    borderRadius: "100vh",
    color: "#276e64",
    fontWeight: "600",
    backgroundColor: "#f2f2f2",
    textTransform: "capitalize",
    marginLeft: "2rem",
    padding: "14px 28px",
    letterSpacing: "2px",
    transition: "transform 200ms",
    "&:hover": {
      backgroundColor: "#fff",
      transform: "scale(1.1)",
      textShadow: "0px 0px 8px rgb(255,255,255)",
      boxShadow: "0px 0px 8px rgb(255 255 255);",
    },
  },
  totalPrice: {
    fontSize: "2rem",
  },
});
export default useStyles;
