import { Grid, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { UsersContext } from "../../context/UsersContext";
import { UserContext } from "../../context/UserContext";
import UserCard from "./UserCard/UserCard";
import VerticalFilter from "./VerticalFilter";

/**
 * This component renders each single user using the UserCard component
 * @module Users Component
 */

function Users() {

  function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
  }

  const images = importAll(require.context('../../assets/images/employees', false, /\.(png|jpe?g|svg)$/));

  const { users, filtered, clearL } = useContext(UsersContext);
  const { isLoggedIn } = useContext(UserContext);
  let renderUsers;
  if (users !== null) {
    let previous = "";
    renderUsers = users.map((user) => {
      let current = user.displayName[0];
      if (current !== previous) {
        previous = current;
        return (
          <Grid
            key={user.id}
            item
            xs={6}
            sm={4}
            md={3}
            lg={2}
            id={`firstLetter${current}`}
          >
            <UserCard user={user} imageSrc={images[`${user.imageUrl}`].default} />
          </Grid>
        );
      } else {
        return (
          <Grid key={user.id} item xs={6} sm={4} md={3} lg={2}>
            <UserCard user={user} imageSrc={images[`${user.imageUrl}`].default} />
          </Grid>
        );
      }
    });
  } else {
    renderUsers = (
      <Grid item xs={12}>
        <Typography>
          Unable to fetch users from the database, check your internet
          connection.
        </Typography>
      </Grid>
    );
  }
  return (
    <>
      <Grid container justify='center'>
        {filtered !== null
          ? filtered.map((user) => {
              return (
                <Grid key={user.id} item xs={6} sm={4} lg={2}>
                  <UserCard user={user} imageSrc={images[`${user.imageUrl}`].default} />
                </Grid>
              );
            })
          : renderUsers}
      </Grid>
      {!isLoggedIn && <VerticalFilter users={users} />}
    </>
  );
}

export default Users;
