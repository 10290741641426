import React, { createContext, useState, useEffect } from "react";
import { getRequest } from "../utils/http/http";
import { Environment } from "../global/environment";
export const UsersContext = createContext();

function UsersState(props) {
  const [users, setUsers] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState(null);

  const filterUsers = (text) => {
    let tempFiltered = users.filter((user) => {
      const regx = new RegExp(`${text}`, "gi");
      return user.displayName.match(regx);
    });
    setFiltered([...tempFiltered]);
  };
  const clearFilter = () => {
    setFiltered(null);
  };

  //Get all users from the api
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getRequest(
          `${Environment.BACKEND_API}/Employee`
        );
        setUsers(response);
        setLoading(false);
      } catch (err) {
        setUsers(null);
        setLoading(false);
        console.log(err);
      }
    }
    fetchData();
  }, []);

  return (
    <UsersContext.Provider
      value={{ users, isLoading, filterUsers, clearFilter, filtered }}
    >
      {props.children}
    </UsersContext.Provider>
  );
}

export default UsersState;
