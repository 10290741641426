import React, { useContext, useState } from "react";
import { Environment } from "../../global/environment";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { useHistory } from "react-router";
import useStyles from "./Styling";
// import BottomNavigation from "@material-ui/core/BottomNavigation";
import { UserContext } from "../../context/UserContext";
import { Box, Button, Typography } from "@material-ui/core";
import DrinkAvatar from "../../components/DrinkAvatar/DrinkAvatar";
import AlertComponent from "../AlertComponent";
import XButton from "./XButton/XButton";
import { postRequest } from "../../utils/http/http";
import "./bottomDockStyles.css";
/**
 * Layout component that displays selected drinks at the bototm of the screen
 * @module BottomDock Component
 */
function BottomDock({ order, totalPrice, clearOrder, finishHandler }) {
  const classes = useStyles();
  const [alert, setAlert] = useState(false);
  const history = useHistory();
  const { id } = useContext(UserContext);

  const handleConfirm = async () => {
    if (totalPrice > 0) {
      // finishOrder();
      await postOrder();

      history.push("/thankyou");
    } else {
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    }
  };
  const postOrder = async () => {
    finishHandler();
    const data = [
      {
        EmployeeId: id,
        TotalPrice: totalPrice,
        OrderItems: order
          .map((drink) => {
            return { DrinkGroupId: drink.id, Quantity: drink.amount };
          })
          .filter((order) => order.Quantity > 0),
      },
    ];
    try {
      const response = await Promise.all([
        postRequest(`${Environment.BACKEND_API}/Order`, data),
        timeout(3000),
      ]);
      console.log("Response: ", response);
    } catch (err) {
      console.log(err);
    }
  };
  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  return (
    <>
      <motion.div
        className={classes.root}
        initial={{ y: 200 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className={classes.leftDiv}>
          <AnimateSharedLayout>
            {order
              // .filter((order) => order.amount > 0)
              .map((order, index) => (
                <AnimatePresence key={order.id}>
                  {order.amount && (
                    <motion.div
                      layout
                      key={order.id}
                      timeout={800}
                      initial={{ x: -350 }}
                      animate={{ x: 0 }}
                      transition={{ type: "spring", duration: 1 }}
                      exit={{ y: 250 }}
                    >
                      <DrinkAvatar
                        key={order.id}
                        amount={order.amount}
                        imgSrc={order.imgUrl}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              ))}
          </AnimateSharedLayout>
        </div>
        <Box className={classes.rightDiv} component='div'>
          <XButton clearOrder={clearOrder} totalPrice={totalPrice} />
          <Box className={classes.rightDivLeft} component='div'>
            <Typography>TOTAL: </Typography>
            <Typography className={classes.totalPrice}>
              Fr. {totalPrice}
            </Typography>
          </Box>

          <Button
            onClick={handleConfirm}
            variant='outlined'
            className={`${classes.btn} btn`}
          >
            Kaufen
          </Button>
        </Box>
      </motion.div>

      {alert && (
        <AlertComponent top={"bottom"} center={"center"}></AlertComponent>
      )}
    </>
  );
}
export default BottomDock;
