import React, { useEffect } from "react";
import { Box, Card, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { motion } from "framer-motion";

/**
 * Thank you component that renders a card after the user is done with the purchase
 * This component uses material-ui hook "makeStyles" for styling
 * @module ThankYou Component
 *
 */
const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
    // backgroundColor: "#276e64",
  },
  card: {
    backgroundColor: "#fff",
    minWidth: "290px",
    maxWidth: "300px",
    borderRadius: "8px",
    color: "#276e64",
    textAlign: "center",
  },
  heading: {
    marginTop: "0.5rem",
    fontWeight: "600",
  },
  cardContent: {
    padding: "2.5rem 2.75rem",
  },
  icon: {
    padding: "1rem 0",
  },
});
function ThankyouPage() {
  const classes = useStyles();
  useEffect(() => {
    setTimeout(() => {
      window.location.replace("/");
    }, 5000);
  }, []);
  return (
    <Box
      className={classes.root}
      component={motion.div}
      initial={{ y: "100vh" }}
      animate={{ y: 0 }}
      transition={{ type: "spring", duration: 1 }}
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <CheckCircleIcon fontSize='large' className={classes.icon} />
          <Typography>Eintrag war erfolgleich!</Typography>
          <Typography className={classes.heading} variant='h4'>
            Vielen Dank
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ThankyouPage;
