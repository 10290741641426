import React, { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
/**
 * Alert layout component used to inform the user they are trying to purchase 0 drinks
 * @module Alert Component
 * @param {Object} props Position of the alert on screen
 */
function PositionedSnackbar(props) {
  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  useEffect(() => {
    setState({ open: true, vertical: props.top, horizontal: props.center });
    // eslint-disable-next-line
  }, []);
  const { vertical, horizontal, open } = state;

  return (
    <div style={{ position: "absolute" }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        message='Please add a drink before purchase'
        key={vertical + horizontal}
      />
    </div>
  );
}
export default PositionedSnackbar;
