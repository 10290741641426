/**
 * Function to get the the date and return it in DE format
 * @function getToday
 * @returns The current day in DE format
 */
//Returns the current day in "DE" format
export const getToday = () => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const today = new Date();
  return today.toLocaleDateString("de", options);
};
