import { useEffect, useRef } from "react";
import AnimateOnChange from "react-animate-on-change";
import useStyles from "./Styling";

/**
 * Displays the number of added drinks on each icon at the bottom dock during the purchase phase
 * @module DrinkAvatarBadge Component
 */
function DrinkAvatarBadge({ amount }) {
  const classes = useStyles();
  const lastAmount = useRef(false);
  useEffect(() => {
    lastAmount.current = true;
    setTimeout(() => {
      lastAmount.current = false;
    }, [500]);
  }, [amount]);

  return (
    // <motion.span
    //   className={classes.icon__badge}
    //   initial={{ scale: 0 }}
    //   animate={{ scale: 1.1 }}
    //   transition={{
    //     delay: 0.5,
    //     duration: 0.5,
    //   }}
    // >
    //   {amount}
    // </motion.span>
    <AnimateOnChange
      baseClassName={classes.icon__badge}
      animationClassName='animate'
      animate={lastAmount.current}
    >
      {amount}
    </AnimateOnChange>
  );
}

export default DrinkAvatarBadge;
