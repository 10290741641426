import React from "react";
import "./verticalfilter.css";
/**
 * Component layout to be able to filter users based on first letter of their first name
 * @module VerticalFilter Component
 * @param {Object} Users
 */
function VerticalFilter({ users }) {
  let firstLetters = [];

  const findLetters = () => {
    let previous = "";
    users.forEach((user) => {
      let current = user.displayName[0];

      if (previous !== current) {
        firstLetters.push({ character: current, id: user.id });
        previous = current;
      }
    });
  };
  findLetters();
  return (
    <ul className='verticalLetters'>
      {firstLetters.map((character) => {
        return (
          <a key={character.id} href={`#firstLetter${character.character}`}>
            {character.character}
          </a>
        );
      })}
    </ul>
  );
}

export default VerticalFilter;
