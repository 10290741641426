import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { UsersContext } from "../../../context/UsersContext";
import searchIcon from "../../../assets/search.svg";
import "./styles.css";
/**
 * This component displays the search icon to be able to filter users
 * @module FilterUsers Component
 */

function FilterUsers() {
  const usersContext = useContext(UsersContext);
  // const searchValue = useRef("");
  const { filterUsers, clearFilter } = usersContext;
  const handleInputField = (e) => {
    if (e.target.value === "") {
      clearFilter();
      return;
    }
    clearFilter();
    filterUsers(e.target.value);
  };
  return (
    <Grid item xs={12}>
      {/* <TextField
        label='Search your profile'
        variant='outlined'
        onChange={(e) => handleInputField(e)}
      /> */}
      <div className='search-bar'>
        <input
          type='text'
          placeholder='search for name'
          aria-label='search'
          className='searchInputField'
          onChange={(e) => handleInputField(e)}
        ></input>
        <button className='searchButton' aria-label='submit search'>
          <img src={searchIcon} alt='search-img' className='searchIcon'></img>
        </button>
      </div>
    </Grid>
  );
}

export default FilterUsers;
