import React from "react";
import { Avatar } from "@material-ui/core";
import useStyles from "./Styling";
import DrinkAvatarBadge from "./DrinkAvatarBadge";

/**
 * This component renders the drink icons at the bottom of the screen
 * This component uses material-ui makeStyles hook for styling
 * @module DrinkAvatar Component
 * @params Image source and the amount
 */

// const badgeVariants = {
//   visible: {
//     scale: [1, 1.1, 1, 1.1, 1, 1.1, 1],
//     transition: { duration: 2 },
//   },
// };
function DrinkAvatar({ imgSrc, amount }) {
  const classes = useStyles();
  return (
    <div className={classes.icon}>
      <Avatar className={classes.avatar}>
        <img className={classes.img} alt='drink-bottom' src={imgSrc} />
      </Avatar>
      <DrinkAvatarBadge amount={amount} />
    </div>
    // <Slide in={props.amount ? true : false} timeout={500} direction='right'>
    // <div className={classes.wrapper}>
    //   <motion.div
    //     initial={{ scale: 0 }}
    //     animate={{ scale: 1.1 }}
    //     transition={{
    //       delay: 0.5,
    //       duration: 0.5,
    //     }}
    //   >
    //     <Badge
    //       color='secondary'
    //       overlap='circle'
    //       badgeContent={props.amount}
    //       className={classes.badge}
    //     ></Badge>
    //   </motion.div>
    //   <Avatar className={classes.avatar}>
    //     <img className={classes.img} alt='drink-bottom' src={props.imgSrc} />
    //   </Avatar>
    // </div>
    // </Slide>
  );
}
export default DrinkAvatar;
