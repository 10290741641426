import React from "react";
import { Grid } from "@material-ui/core";
import Drinks from "../../components/Drinks/Drinks";
import { makeStyles } from "@material-ui/styles";
import { motion } from "framer-motion";

/**
 * Drinks-menu component layout that renders drinks
 * This component uses material-ui hook "makeStyles" for styling
 * @module DrinksMenu Component
 */
const useStyles = makeStyles(() => ({
  root: {
    // paddingTop: "30px",
    marginBottom: "105px",
    minHeight: "70vh",
  },
}));
/**
 * Layout component used to render all available drinks
 * @returns Drinks available to purchase
 */
const DrinksPage = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      spacing={2}
      className={classes.root}
    >
      <Drinks />
    </Grid>
  );
};

export default DrinksPage;
