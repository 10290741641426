import axios from 'axios';

function handleHttpError(error){
    if(error.response){
        if(error.response.data){
            return {
                ...error.response.data,
                statusCode: error.response.status,
                message: error.response.errorMessage || 'genericErrorMessage'
            }
        }
    }
    return {
        timeout: true,
        message: 'genericErrorMessage'
    };
}

function makeHttpRequest(apiCall){
    return new Promise(async (resolve, reject) => {
        try{
            const { data } = await apiCall();
            resolve(data);
        }catch(e){
            reject(handleHttpError(e));
        }
    });
}

export function getRequest(path, options = {}){
    return makeHttpRequest(() => axios.get(path, options));
}

export function postRequest(path, data = {}, options = {}){
    return makeHttpRequest(() => axios.post(path, data, options));
}