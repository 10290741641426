import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Button, Avatar } from "@material-ui/core";
import { UserContext } from "../../../context/UserContext";
import { UsersContext } from "../../../context/UsersContext";
import "./styles.css";
/**

 * This component renders info about each user.
 * This component uses material-ui makeStyles hook for styling
 * @module UserCard Component
 */
const useStyles = makeStyles((theme) => ({
  cardStyles: {
    minWidth: "160px",
    backgroundColor: "#f0f2f5",
    transition: "background 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "#D8DADF !important",
    },
  },
  large: {
    transition: "all 300ms",
    width: theme.spacing(16),
    height: theme.spacing(16),
    border: " 2px solid rgb(102 102 102 / 13%)",
    "&:hover": {
      cursor: "pointer",
      // transform: "scale(1.1)",
    },
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  link: {
    textDecoration: "none",
    color: "#666",
  },
  userButton: {
    backgroundColor: "#fff",
    fontSize: "12px",
    padding: "5px 15px",
    letterSpacing: "1px",
    color: "#666",
    "&:hover": {
      backgroundColor: "#f3f3f3",
    },
  },
  span: {},
}));

function UserCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const { displayName, id } = props.user;
  const { imageSrc } = props;

  const { handleLogin } = useContext(UserContext);
  const { clearFilter } = useContext(UsersContext);

  const handleOnClick = () => {
    handleLogin(displayName, id);
    history.push("/drinks");
    clearFilter();
  };
  return (
    <Card
      style={{ border: "none", boxShadow: "none" }}
      className={`${classes.cardStyles} userCard`}
    >
      <CardContent className={classes.cardContent}>
        <Avatar
          style={{ alignSelf: "center" }}
          alt='Remy Sharp'
          src={imageSrc}
          className={`${classes.large} userImage`}
          onClick={handleOnClick}
        />
      </CardContent>
      <CardActions className={classes.cardContent}>
        <Button
          onClick={handleOnClick}
          variant='contained'
          size='small'
          className={classes.userButton}
          // disableElevation
        >
          {displayName}
        </Button>
      </CardActions>
    </Card>
  );
}

export default UserCard;
