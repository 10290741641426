import React, { createContext, useState } from "react";
export const UserContext = createContext();
/**
 * This component is used to store the logged in user
 * @module UserState Context-Component

 */
function UserState(props) {
  const [isLoggedIn, setLoggin] = useState(false);
  const [displayName, setUserName] = useState("");
  const [id, setId] = useState(null);

  const handleLogin = (username, id) => {
    setUserName(username);
    setId(id);
    setLoggin(true);
    // return new Promise(() => setTimeout(() => {}, 500));
  };

  const clearLogin = () => {
    setLoggin(false);
    setUserName("");
    setId(null);
  };
  const goBack = () => {
    setUserName("");
    setId(null);
    setLoggin(false);
  };
  return (
    <UserContext.Provider
      value={{ isLoggedIn, displayName, id, handleLogin, clearLogin, goBack }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

export default UserState;
