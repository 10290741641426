import React from "react";
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { motion, AnimatePresence } from "framer-motion";

/**
 * XButton component that clears all drinks at once
 * @module XButton Component
 */
const buttonVariants = {
  hidden: {
    y: 200,
    rotate: -360,
  },
  visible: {
    rotate: 0,
    y: 0,
    transition: { duration: 1 },
  },
};
const useStyles = makeStyles({
  root: {
    backgroundColor: "#e22250",
    width: "50px",
    height: "50px",
    marginRight: "2rem",
    fontSize: "15px",
    cursor: "pointer",
  },
});
function XButton({ clearOrder, totalPrice }) {
  const handleX = () => {
    clearOrder();
  };
  const classes = useStyles();
  return (
    <AnimatePresence>
      {totalPrice && (
        <Avatar
          onClick={handleX}
          className={classes.root}
          component={motion.div}
          variants={buttonVariants}
          initial='hidden'
          animate='visible'
          exit={{ y: 200, rotate: 360 }}
          transition={{ duration: 1 }}
        >
          X
        </Avatar>
      )}
    </AnimatePresence>
  );
}

export default XButton;
