import React from "react";
import { Box, IconButton } from "@material-ui/core";
import { motion } from "framer-motion";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import useStyles from "./Styling";
import "./styles.css";
/**
 * Component that renders info for each drink
 * This component uses material-ui makeStyles hook for styling
 * @module DrinkCard Component
 */
const DrinkCard = (props) => {
  const classes = useStyles();
  const { name, price, id, amount, increment, decrement, imgSrc } = props;
  const handleIncrement = () => {
    increment(id, name);
  };
  const handleDecrement = () => {
    decrement(id, name);
  };
  const handleInput = (e) => {
    console.log("input: ", e.target.value);
  };

  return (
    <Card
      className={`${classes.root} drinkCard`}
      elevation={2}
      // component={motion.div}
      // initial={{ x: "100vw" }}
      // animate={{ x: 0 }}
      // transition={{ type: "spring", delay: 0.2, duration: 1.3 }}
    >
      <div className={classes.drinkImageWrapper}>
        <CardMedia
          component='img'
          alt='coffe'
          image={imgSrc}
          className={classes.drinkImage}
        />
      </div>
      <Box className={classes.cardBox}>
        <CardContent className={classes.cardContent}>
          <Typography
            className={classes.title}
            gutterBottom
            variant='h4'
            component='h2'
          >
            {name}
          </Typography>
          <Box className={classes.quantity}>
            <IconButton
              onClick={handleDecrement}
              className={classes.icons}
              edge='start'
            >
              <RemoveCircleIcon className={classes.addIcon} />
            </IconButton>

            <input
              className={`${classes.amount} ${
                amount === 0 ? "empty" : "notEmpty"
              }`}
              value={amount}
              readOnly
              onChange={(e) => handleInput(e)}
              // onClick={(e) => handleInputClick(e)}
            ></input>

            <IconButton
              onClick={handleIncrement}
              className={classes.icons}
              edge='end'
            >
              <AddCircleIcon className={classes.addIcon} />
            </IconButton>
          </Box>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Typography className={classes.cardSpan}>Fr. {price}</Typography>
        </CardActions>
      </Box>
    </Card>
  );
};
export default DrinkCard;
