import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  icon: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "60px",
    height: "60px",
    marginRight: "0.75rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon__badge: {
    position: "absolute",
    top: "-7px",
    right: "-7px",
    width: "24px",
    height: "24px",
    fontWeight: "500",
    // fontSize: "14px",
    fontFamily: "Roboto",
    backgroundColor: "#f2f2f2",
    borderRadius: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #276e64",
    color: "#276e64",
  },
  animate__icon__badge: {
    transform: "scale(1.2)",
    transition: "all 500ms",
  },
  wrapper: {
    position: "relative",
  },
  avatar: {
    width: "60px",
    height: "60px",
    padding: "3px",
    color: "#fff",
    backgroundColor: "#f2f2f2",
  },
  img: {
    position: "absolute",
    height: "50px",
    zIndex: 1,
  },
  badge: {
    fontSize: "5px",
  },
});
export default useStyles;
