/**
 * Main app component that renders the three pages of the app accordingly
 * This app uses Material-UI components/styling for styling
 * @module Main Component
 */
import { Switch, Route, useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";
import DrinksPage from "./pages/DrinksPage/DrinksMenu";
import Thankyou from "./pages/thankYouPage/ThankYou";
import Home from "./pages/UsersPage/HomeUsers";
import Header from "./layout/Header";
import UserState from "./context/UserContext";
import UsersState from "./context/UsersContext";
import OrderState from "./context/OrderContext";
import PrivateRoute from "./components/routing/PrivateRoute";
import { makeStyles } from "@material-ui/styles";
import { AnimatePresence } from "framer-motion";
import "./App.css";

const useStyles = makeStyles({
  root: {
    // width: "100vw",
    // backgroundColor: "#ecf0f4",
  },
  main: {
    overflow: "hidden",
  },
});
function App() {
  const classes = useStyles();
  const location = useLocation();

  return (
    <UserState>
      <UsersState>
        <OrderState>
          <Grid container direction='column' className={classes.root}>
            <Grid item xs={12}>
              <Header />
            </Grid>
            <Grid item container justify='center' className={classes.wrapper}>
              <Grid item xs={12} sm={10} className={classes.main}>
                {/* <AnimatePresence> */}
                {/* Show the page based on which step you are */}
                <Switch location={location} key={location.key}>
                  <Route exact path='/' component={Home} />
                  <PrivateRoute path='/drinks' component={DrinksPage} />
                  <PrivateRoute exact path='/thankyou' component={Thankyou} />
                  <Route component={Home} />
                </Switch>
                {/* </AnimatePresence> */}
              </Grid>
            </Grid>
          </Grid>
        </OrderState>
      </UsersState>
    </UserState>
  );
}

export default App;
