import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles({
  root: {
    // maxWidth: 180,
    minWidth: 180,
    backgroundColor: "#276e64",
    borderRadius: "12px",
    border: 0,
    outline: 0,
    position: "relative",
    textAlign: "center",
  },
  cardHeader: {
    height: "40px",
  },
  cardContent: {
    borderRadius: "12px",
    backgroundColor: "#fff",
    paddingTop: "4rem",
    padding: "10px",
  },
  cardBox: {
    borderRadius: "12px",
    marginTop: "90px",
    backgroundColor: "#ebeff3",
  },
  drinkImage: {
    // width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  drinkImageWrapper: {
    position: "absolute",
    // maxWidth: "65px",
    height: "110px",
    zIndex: "1",
    left: "50%",
    transform: "translate(-50%,0)",
    top: "20px",
  },
  title: {
    fontSize: "20px",
    color: "#46576d",
    fontWeight: "500",
    // "&::before": {
    //   content: '""',
    //   display: "block",
    //   height: "2px",
    //   width: "20%",
    //   backgroundColor: "#276e64",
    //   margin: "1rem auto 1rem auto",
    //   borderRadius: "15px",
    // },
  },
  cardActions: {
    backgroundColor: "#f2f2f2",
    justifyContent: "center",
  },
  cardSpan: {
    color: "#276e64",
    fontWeight: "500",
  },
  quantity: {
    padding: "0",
    marginTop: "0.75rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  media: {
    height: 140,
  },
  btn: {
    color: "#ebeff3",
    "&:hover": {
      color: "green",
    },
  },
  btnRemove: {
    color: "#ebeff3",
    "&:hover": {
      color: "red",
    },
  },
  iconWrapper: {
    width: "28px",
    height: "28px",
    borderRadius: "50%",
    backgroundColor: "#444",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  amount: {
    maxWidth: "50px",
    borderRadius: "100vh !important",
    textAlign: "center",
    height: "27px",
    outline: "none",
    border: "2px solid #eaeef2",
    "&:focus": {
      border: "2px solid #276e64",
    },
  },
  icons: {
    padding: "12px 10px",
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  addIcon: {
    fontSize: "2.25rem",
    transition: "transform 200ms",
    color: "#276e64",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
});
export default useStyles;
